/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import {
    DEAL_TYPES_FILTER,
    IMPACT_THEMES_FILTER,
    PAGE_EVENTS,
    REGIONS_FILTER,
    SECTORS_AND_SUB_SECTORS_FILTER,
    BLENDING_ARCHETYPE_FILTER
} from "../../../constants";
import BaseContentfulPagedListing from "../../general/BaseContentfulPagedListing";
import Loader from "../../layout/Loader";
import CapacityBuildingEventBox from "./CapacityBuildingEventBox";

type Props = {
    currentRouteKey: string;
    // TS no user or enableFilters here
};
class ContentfulEventsListing extends BaseContentfulPagedListing<Props> {
    initialFilters: { [key: string]: string | string[] };
    constructor(props) {
        super(props);

        this.contentType = PAGE_EVENTS;

        const queryData = this.getQueryData();
        this.initialFilters = {
            access: ["Public", "Members"],
            "fields.date[gt]": [new Date().toISOString()],
            ...(typeof queryData.filters === "object" ? queryData.filters : {})
        };

        this.availableFilters = {
            instrumentFocus: DEAL_TYPES_FILTER,
            blendingInstrument: BLENDING_ARCHETYPE_FILTER,
            sectors_and_sub_sectors: SECTORS_AND_SUB_SECTORS_FILTER,
            regions: REGIONS_FILTER,
            impact_themes: IMPACT_THEMES_FILTER
            // type: LEARNING_TYPES_FILTER
        };

        this.defaultFilter = "instrumentFocus";

        this.state = {
            loadingMore: false,
            disableLoadMore: false,
            loading: true,
            skip: 0,
            limit: 12,
            contentful: {
                orderDirection: "ASC",
                orderByField: "fields.date",
                query: queryData.keyword ? queryData.keyword : "",
                filters: this.initialFilters
            },
            articles: []
        };
    }

    render() {
        const { loading, loadingMore, disableLoadMore, articles } = this.state;
        const { currentRouteKey } = this.props;
        return (
            <>
                <style jsx>
                    {`
                        .library-bar {
                            background-color: #d3d3d3;
                            padding-top: 2rem;
                        }
                        .library-bar .filters-bar {
                            background-color: #d3d3d3;
                        }
                    `}
                </style>
                <div className={"pt-4 pb-4"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            {loading && (
                                <div className={"col-12"}>
                                    <Loader />
                                </div>
                            )}
                            {!loading && (
                                <React.Fragment>
                                    {articles &&
                                        articles.length > 0 &&
                                        articles.map((article) => {
                                            return (
                                                <CapacityBuildingEventBox
                                                    key={article.id}
                                                    event={article}
                                                    // showIntro
                                                    calendar
                                                />
                                            );
                                        })}
                                    {articles && articles.length <= 0 && (
                                        <div className={"col-12"}>
                                            <div className={"alert alert-info"}>
                                                Sorry, no resources were found.
                                                Try changing your search or
                                                filter criteria.
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        {!disableLoadMore && (
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <button
                                        className={"btn btn-primary"}
                                        onClick={this.loadMore}
                                        disabled={loading || loadingMore}
                                    >
                                        {loadingMore
                                            ? "Loading..."
                                            : "Load More"}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ContentfulEventsListing;
